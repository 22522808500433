import React from 'react'
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from 'react-native'

export function ShadowBox({
  style,
  children,
  onLayout,
}: {
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
  onLayout?: (e: LayoutChangeEvent) => void
}) {
  return (
    <View
      style={[
        {
          padding: 32,
          borderRadius: 8,
          shadowRadius: 4,
          elevation: 4,
          shadowColor: '#000',
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: 2 },
          backgroundColor: 'white',
        },
        style,
      ]}
      onLayout={onLayout}
    >
      {children}
    </View>
  )
}
